<template>
    <section class="cobro-cartera-main container-fluid">
        <navbar-admin icon="icon-account-cash" titulo="Pagos proveedores"/>
        <tabs :tabs="tabsVerProveedores" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            tabsVerProveedores: [
                {
                    titulo:'Cuentas por pagar',
                    ruta:"cobro.cartera.pagar",
                },
                // {
                //     titulo:'Cuentas por cobrar',
                //     ruta:"cobro.cartera.cobrar",
                // },
            ],
        }
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
.cobro-cartera-main{
    background: white;
}
</style>